.sports__events_wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
}

.sports-events_image-wrap{
    width: 300px;
    height: 300px;
    /*border: 1px solid #000;*/
    background-color: beige;
    margin-bottom: 20px;
}

.list_sports-events{
    list-style: none;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    width: 300px;
    height: fit-content;
}

.list_sports-events__item{
    cursor: pointer;
}

.desc_sports-events{
    margin-top: 20px;
}
