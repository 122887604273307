.card__sports-events_content--image{
    opacity: 1;
}

.card__sports-events_content--image-hover{
    opacity: 0.7;
    /*box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.5), 
    0px 2px 4px rgba(0,0,0,0.1),
    0px 4px 8px rgba(0,0,0,0.1),
    0px 8px 16px rgba(0,0,0,0.1);*/
    box-shadow: 0 0 5px 5px #ccee7c;
    border-radius: 6px;
}

.card__sports-events_image-wrap{
    width: 40px;
    height: 40px;    
}

.item-img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}
