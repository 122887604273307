.sidebar{
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 70px;
  }
  
  .sidebar button{
    box-shadow: 0px 0px 10px rgb(236, 235, 148);
    border-radius: 8px;
    border:none;
    background-color: white;
    height: 100px;
    width: 100px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    color:black;
    cursor: pointer;
  }
