/*}.spring-wrap{
    width: 1000px;
    height: 620px;
    text-align: center;
    margin: 0 auto 50px;
    background-color: brown;
}*/

*{
    box-sizing: border-box;
}

body{
    background-color: #eff3f7;
    margin: 0;
    padding: 0;
    height: 100%;
}

/*html,
body,
#root,*/
.container{
    height: 100%;
    display: flex;
    justify-content: center;
}

.loader{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
}

.movies{
    display: flex;
    justify-content: space-between;

    align-items: flex-start;

    flex-wrap: wrap;
    padding: 50px;
    padding-top: 70px;
    /*/width: 80%;*

    /*height: fit-content;*/
}

.movies_link{
    display: block;
    width: 45%;
}

@media (max-width: 992px) {
    .movies{
        width: 100%;
    }
}
