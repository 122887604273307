.nav-wrap{
    max-width: 1900px;
    width: 100%;
    padding: 5px;
    height: fit-content;
    background-color: blueviolet;
    margin: 0 0 30px 0;
}

.nav-link{
    width: fit-content;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.link-nav_item{
    text-decoration: none;
    font-size: 24px;
    line-height: 24px;
    color: black;
    background-color: #c3d6cb;
    padding: 5px;
    margin: 20px 0 20px 0;
}

.link-nav_item + .link-nav_item{
    margin-left: 20px;
}

.link-nav_item.active{
    background-color: rgb(0, 119, 255);
    color: aliceblue;
    text-decoration: underline;
}

.link-nav_item:hover{
    text-decoration: none;
    background-color: brown;
    color: aliceblue;
}
