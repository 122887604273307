.app{
    /*display: flex;
    justify-content: space-between;
    max-width: 960px;
    width: 100%;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 960px;
    width: 100%;
    padding: 5px;
    margin: 0 auto;
  }

.components-select{
  margin-bottom: auto;
  margin-top: auto;
}
  