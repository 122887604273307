.movie{    
    display: flex;
    /*width: 45%;*/
    background-color: white;
    align-items: flex-start;
    margin-bottom: 70px;
    text-decoration: none;
    justify-content: space-between;
    font-weight: 300;
    padding: 20px;
    border-radius: 5px;
    color: #adaeb9;
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
     0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
}

.movie a{
    text-decoration: none;
}

.movie_desc{
    width: 80%;
}

.movie img{
    position: relative;
    max-width: 150px;
    width: 100%;
    top: -50px;
    margin-right: 30px;
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
     0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
}

.movie_title,
.movie_year{
    font-weight: 300;
    margin: 0;
}

.movie_title{
    margin-bottom: 5px;
    font-size: 24px;
    color: #2c2c2c;
}

.genres_list{
    list-style: none;
    padding: 0;
    margin: 5px 0;
    display: flex;
    flex-wrap: wrap;
}

.genres_list-item,
.movie_year{
    font-size: 14px;
    margin-right: 10px;
}

@media (max-width: 895px) {
    .movie{
        width: 100%;
    }
}

@media (max-width: 895px) {
    .movie{
        padding: 10px;
    }
    .movie_title{
        font-size: 20px;
    }
}

@media (max-width: 540px) {
    .movie{
        flex-direction: column;
    }
    .movie_desc{
        width: 100%;
    }
}
