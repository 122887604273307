.spring-element_wrap{
    background-color: aqua;
    text-align: center;
    max-width: 500px;
    width: 100%;
    height: fit-content;
    padding-top: 20px;
    padding-bottom: 30px;
    margin: 0 auto 50px;
}

.button-spring-element{
    /*padding: 10px;*/
    cursor: pointer;
    /*background-size: cover;
    background-repeat: no-repeat;
    background-position: center;*/
    width: 40px;
    height: 70px;
    margin: 0 auto;
}

.button-spring-element__image{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container-image{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 418px;
    width: 100%;
    height: 200px;
    padding: 41px;
    margin: 160px auto 30px;
    position: relative;
}

.item-img__wrap{
    /*background-size: cover;
    background-repeat: no-repeat;
    background-position: center;*/
    /*max-width: 300px;
    width: 100%;
    height: 200px;
    margin: 0 auto;*/
    /*background-color: aliceblue;*/
    max-width: 418px;    
    width: 100%;
    height: 200px;    
    position: absolute;
    top: 37px;
    left: 0;
    /*box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;*/
}

.item-img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.items-img__wrap{
    max-width: 418px;    
    width: 100%;
    height: 200px;    
    position: absolute;
    top: -174px;
    right: 0;
}

.items-img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
