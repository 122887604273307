.home{
    /*width: calc(100% - 250px);
    display: flex;
    justify-content:center;
    align-items: center;*/
    width: 150px;
    height: 150px;
    margin-bottom: 150px;
  }
  .shape{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
